// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {tNZ4rDrCj: {hover: true, pressed: true}};

const cycleOrder = ["tNZ4rDrCj"];

const variantClassNames = {tNZ4rDrCj: "framer-v-585vkx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, C5wFgmuty: tap ?? props.C5wFgmuty} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, C5wFgmuty, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "tNZ4rDrCj", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1bxhykw = activeVariantCallback(async (...args) => {
if (C5wFgmuty) {
const res = await C5wFgmuty(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-XA4yx", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-585vkx", className)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"tNZ4rDrCj"} onTap={onTap1bxhykw} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-e0c1a430-e94c-4848-b21a-15c5186602da, rgb(40, 40, 40)) /* {\"name\":\"Border\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-0961c7c7-3bc7-4e48-af90-60b64f198bf1, rgb(28, 28, 28))", borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, ...style}} variants={{"tNZ4rDrCj-hover": {"--border-color": "rgb(59, 59, 59)"}}} {...addPropertyOverrides({"tNZ4rDrCj-hover": {"data-framer-name": undefined}, "tNZ4rDrCj-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-14rawxe-container"} layoutDependency={layoutDependency} layoutId={"N0WjUhpct-container"}><Phosphor color={"var(--token-25b78926-876e-4135-844a-901d863a7741, rgb(242, 242, 242)) /* {\"name\":\"White\"} */"} height={"100%"} iconSearch={"House"} iconSelection={"X"} id={"N0WjUhpct"} layoutId={"N0WjUhpct"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-XA4yx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XA4yx .framer-10c2ssn { display: block; }", ".framer-XA4yx .framer-585vkx { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; will-change: transform; }", ".framer-XA4yx .framer-14rawxe-container { flex: none; height: 12px; left: calc(50.00000000000002% - 12px / 2); position: absolute; top: calc(50.00000000000002% - 12px / 2); width: 12px; }", ".framer-XA4yx .framer-v-585vkx .framer-585vkx { cursor: pointer; }", ".framer-XA4yx.framer-v-585vkx.hover .framer-585vkx, .framer-XA4yx.framer-v-585vkx.pressed .framer-585vkx { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"tu5AN1Ktp":{"layout":["fixed","fixed"]},"RtXdzd5rX":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"C5wFgmuty":"tap"}
 */
const FrameryHPrvYJrC: React.ComponentType<Props> = withCSS(Component, css, "framer-XA4yx") as typeof Component;
export default FrameryHPrvYJrC;

FrameryHPrvYJrC.displayName = "Closeable";

FrameryHPrvYJrC.defaultProps = {height: 32, width: 32};

addPropertyControls(FrameryHPrvYJrC, {C5wFgmuty: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FrameryHPrvYJrC, [...PhosphorFonts])